import { useStore } from "@/store"
import { computed } from "vue"
import { useQuizzesSubscription } from "@/composables/useQuizzesSubscription"
import { useSchoolsUser } from "@/composables/useSchoolsUser"


export function useScore(score) {
  const store = useStore()

  const schoolsUser = computed(() => {
    return store.getters.schoolsUsers.find(su => su.user_id === score.user_id)
  })
  const subscription = computed(() => {
    return store.getters.quizzesSubscription(score.quiz_id, schoolsUser.value?.id)
  })

  const schoolsUserFacade = computed(() => {
    return useSchoolsUser(schoolsUser.value).facade()
  })

  const subscriptionFacade = computed(() => {
    return useQuizzesSubscription(subscription.value).facade()
  })

  const timeAccommodation = computed(() => {
    return store.getters.timeAccommodation(score.time_accommodation_id)
  })

  const session = computed(() => {
    return store.getters.quizSessions(score.quiz_id).find(s => s.id === score.quizzes_session_id)
  })

  function facade() {
    return {
      ...score,
      // email: schoolsUser.value?.email || "",
      // name: schoolsUser.value?.name || "",
      // last_name: schoolsUser.value?.last_name || "",
      // teacher: schoolsUser.value?.teacher || "",
      // mouseExitCount: score.cheat?.count,
      mouseExitDuration: score.cheat?.duration,
      devices: score.devices,
      schoolsUser: schoolsUser.value,
      subscription: subscription.value,
      timeAccommodation: timeAccommodation.value,
      success_levels_grade: score.grade, //used only to allow sorting in score table
      session: session.value,
    }
  }

  return {
    facade,
    schoolsUser,
    schoolsUserFacade,
    subscription,
    subscriptionFacade,
    timeAccommodation,
  }
}
